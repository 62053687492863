// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
// Hooks.
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Assets.
import styles from './featured-job.module.scss';
import {has, get} from 'lodash'

const FeaturedJob = ({ featuredJob }) => {
  const reservedImage = get(useImageStaticQuery(featuredJob.image), 'childImageSharp.fluid', null);
  const image = has(featuredJob, 'image.fluid') ? featuredJob.image.fluid :  reservedImage;

  return (
    <div className={styles.featuredJobContainer}>
      {!!image && <Img className={styles.featuredJobImage} fluid={image} />}
      <h3>{featuredJob.title}</h3>
      <div dangerouslySetInnerHTML={{__html: featuredJob.description}}/>
      <a
        className={styles.featuredJobLink}
        href={featuredJob.link.url}
        title={featuredJob.link.title}
        target="_blank"
        rel="noopener noreferrer"
      >
        {featuredJob.link.title}
      </a>
    </div>
  );
};

FeaturedJob.propTypes = {
  featuredJob: PropTypes.object.isRequired,
};

export default FeaturedJob;
