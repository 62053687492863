// Libs.
import React from 'react';
// Components.
import FindYourPlace from './find-your-place';
import CareerBenefits from './career-benefits';
import PromotionalBanner from './promotional-banner';
import CareerQuote from './career-quote';
import CareerHero from './career-hero';
// Assets.
import styles from './careers-landing.module.scss';
import {useCareerPageQuery} from "../../hooks/useCareerPageQuery";

const CareersLanding = () => {
  const {configPagesCareers: data} = useCareerPageQuery();
  return (
    <div className={styles.careersLandingBackground}>
      <CareerHero hero={data.heroBanner} />
      <FindYourPlace featuredJobs={data.jobs} title={data.label}/>
      <CareerBenefits benefits={{heading: data.heading, image: data.image, items: data.items}} />
      <PromotionalBanner promoBanner={data.largeCta} />
      <CareerQuote quote={{author: data.author, title: data.quoteBody}} />
    </div>
  );
};

export default CareersLanding;
