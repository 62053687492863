import React, { useMemo } from 'react';
import styles from './promotional-banner.module.scss';
import ClassCta from '../homepage/class-cta';

const PromotionalBanner = ({ promoBanner }) => {

  return useMemo(
    () => (
      <div className={styles.promotionalBanner}>
        <ClassCta data={promoBanner} />
      </div>
    ),
    [promoBanner]
  );
};

export default PromotionalBanner;
