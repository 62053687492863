import { useStaticQuery, graphql } from 'gatsby';
import {drupalTransformer} from '../libs/transform/index'

export const useCareerPageQuery = () => {
  return drupalTransformer.transformNode(useCareerPage());
};

const useCareerPage = () => {
  return useStaticQuery(graphql`
    query Career {
      configPagesCareers {
        heading:field_delivery_title
        author:field_cta_title
        relationships {
          image:field_cta_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
          field_jobs {
            uuid:id
            title:field_type_label
            description:field_answer {
              value
            }
            field_link {
              url:uri
              title
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid {
                      srcSet
                      src
                      base64
                      aspectRatio
                      sizes
                    }
                  }
                }
              }
            }
          }
          field_large_cta {
            title:field_type_label
            summary:field_answer {
                value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid {
                      srcSet
                      src
                      base64
                      aspectRatio
                      sizes
                    }
                  }
                }
              }
            }
          }
          field_hero_banner {
            title:field_type_label
            body:field_answer {
                value
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid {
                      srcSet
                      src
                      base64
                      aspectRatio
                      sizes
                    }
                  }
                }
              }
            }
          }
          items:field_cta_list {
            description:field_answer {
              value
            }
            title:field_type_label
            uuid:id
          }
          field_cta_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
        field_cta_title
        field_label
        field_quote_body
      }
    }


  `);
};
