// Libs.
import React from 'react';
// Components.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import CareersLanding from '../components/careers/careers-landing';

const CareersPage = () => {
  return (
    <Layout>
      <SEO title="Careers" />
      <CareersLanding />
    </Layout>
  );
};

export default CareersPage;
