// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
// Components.
import FeaturedJob from './featured-job';
// Assets.
import styles from './find-your-place.module.scss';

const FindYourPlace = ({ title, featuredJobs }) => {
  return (
    <div className={styles.findYourPlaceContainer}>
      <h2>{title}</h2>
      <div className={styles.findYourPlaceFeaturedJobs}>
        {featuredJobs.map((item) => (
          <FeaturedJob key={item.uuid} featuredJob={item} />
        ))}
      </div>
    </div>
  );
};

FindYourPlace.propTypes = {
  featuredJobs: PropTypes.array.isRequired,
};

export default FindYourPlace;
