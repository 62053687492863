import React, { useMemo } from 'react';
import styles from './career-quote.module.scss';
import Quote from '../common/quote';

const CareerQuote = ({ quote }) => {
  return useMemo(
    () => (
      <div className={styles.careerQuote}>
        <Quote data={quote} />
      </div>
    ),
    [quote]
  );
};

export default CareerQuote;
