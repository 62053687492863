// Libs
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
// Deps
import ImageHero from '../common/image-hero/image-hero';
// Assets
import styles from './career-hero.module.scss';

const CareerHero = ({ hero }) => {
  return useMemo(
    () => (
      <div className={styles.careerHero}>
        <ImageHero data={hero} />
      </div>
    ),
    [hero]
  );
};

CareerHero.propTypes = {
  hero: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
};

export default CareerHero;
