// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
// Hooks.
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Assets.
import styles from './career-benefits.module.scss';
import {has, get} from 'lodash';

const CareerBenefits = ({ benefits }) => {
  const imageData = get(useImageStaticQuery(benefits.image), 'childImageSharp.fluid');
  const image = has(benefits, 'image.fluid') ? benefits.image.fluid : imageData;
  return (
    <div className={styles.careerBenefitsContainer}>
      {!!image && <Img fluid={image} />}
      <div className={styles.careerBenefitsContent}>
        <h2>{benefits.heading}</h2>
        {benefits.items.map((item) => {
          return (
            <div key={item.uuid}>
              <h3>{item.title}</h3>
              <div dangerouslySetInnerHTML={{__html: item.description}}/>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CareerBenefits.propTypes = {
  benefits: PropTypes.object.isRequired,
};

export default CareerBenefits;
